import { Payment } from '../services/payments'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrencyFormatter } from '../hooks/formatter'
import { PaymentIntent } from '@stripe/stripe-js'
import classNames from 'classnames'
import { twMerge } from 'tailwind-merge'

interface PaymentDetailsProps {
  payment: Payment
  paymentIntent?: PaymentIntent
}

export default function PaymentDetails ({ payment, paymentIntent, children }: PropsWithChildren<PaymentDetailsProps>): JSX.Element {
  const { t } = useTranslation()
  const formatter = useCurrencyFormatter()
  const usdFormatter = useCurrencyFormatter('USD')

  const amountPaid = useMemo(() => {
    if (paymentIntent === undefined || paymentIntent.status !== 'succeeded') {
      return undefined
    }

    return paymentIntent.amount / 100
  }, [paymentIntent])

  const paymentIntentStatus = useMemo(() => {
    if (paymentIntent === undefined) {
      return undefined
    }

    switch (paymentIntent.status) {
      case 'succeeded':
        return 'succeeded'
      case 'processing':
      case 'requires_confirmation':
        return 'processing'
      case 'canceled':
      case 'requires_payment_method':
      case 'requires_action':
        return 'canceled'
    }
  }, [paymentIntent])

  return (
    <dl className='text-sm my-3 grid grid-cols-[1fr_auto] mb-8 gap-4 bg-brand-well max-w-xl p-4 font-bold rounded'>
      {payment.booking_number !== null && (
        <>
          <dt>{t('payment:booking_number', 'Booking number')}:</dt>
          <dd className='text-brand-primary font-bold text-right'>{payment.booking_number}</dd>
        </>
      )}
      <dt>{t('payment:amount_to_pay', 'Amount to pay')}:</dt>
      <dd className='text-brand-primary font-bold text-right'>{formatter.format(payment.amount)}</dd>
      {payment.market === 'north_america' && (
        amountPaid === undefined
          ? (
            <>
              <dt>{t('payment:estimated_amount_in_dollars', 'Estimated amount to pay in USD')}*:</dt>
              <dd className='text-brand-primary font-bold text-right'>{usdFormatter.format(payment.amount * payment.exchange_rate)}</dd>
            </>
            )
          : (
            <>
              <dt>{t('payment:paid_amount_in_dollars', 'Amount paid in USD')}:</dt>
              <dd className='text-brand-primary font-bold text-right'>{usdFormatter.format(amountPaid)}</dd>
            </>
            )
      )}
      {paymentIntentStatus !== undefined && (
        <>
          <dt>{t('payment:payment_status', 'Payment status')}:</dt>
          <dd className={twMerge(classNames('text-brand-primary font-bold text-right', { 'text-red-500': paymentIntentStatus === 'canceled' }))}>{t(`payment:status.${paymentIntentStatus}`, paymentIntentStatus)}</dd>
        </>
      )}
      {children}
    </dl>
  )
}
