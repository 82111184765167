import { PaymentContext } from '../contexts/payment'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Payment from '../components/payment'
import { Navigate } from 'react-router-dom'
import { PaymentService } from '../services/payments'
import Container from '../components/container'
import PaymentDetails from '../components/payment-details'
import { Lock } from 'react-feather'
import poweredByStripe from '../assets/powered-by-stripe.svg'

export default function InitiatePayment (): JSX.Element {
  const { payment } = useContext(PaymentContext)
  const { t } = useTranslation()

  const redirectUri = useMemo(() => `/payments/${payment.id}/completed`, [payment.id])

  const getIntent = useCallback(async (paymentMethodId: string) => {
    const paymentService = new PaymentService()
    return await paymentService.createPayment(payment.id, paymentMethodId)
  }, [payment.id])

  if (payment.status !== 'Open') {
    return (<Navigate to={`${redirectUri}?redirect_status=succeeded`} />)
  }

  return (
    <Container>
      <div className='grid grid-cols-1 lg:grid-cols-6 gap-x-10 gap-y-0 lg:gap-y-4'>
        <div className='lg:col-span-3'>
          <h2
            className='text-brand-primary text-lg font-bold my-8'
          >{t('payment:payment_title', 'Fulfill your outstanding payment')}
          </h2>
          <p
            className='my-3'
          >{t('payment:payment_description', 'Please proceed with the payment for your booking.')}
          </p>
          <PaymentDetails payment={payment} />
        </div>
        <div className='lg:col-span-3 mb-8 flex flex-col justify-end'>
          <div className='rounded-lg flex flex-col lg:items-end'>
            <div className='flex gap-2 items-center text-[#0a2540]'>
              <Lock size={24} />
              <span>Guaranteed <strong>safe &amp; secure</strong> checkout</span>
              <img src={poweredByStripe} width={150} />
            </div>
          </div>
        </div>
        <div className='lg:col-span-6'>
          <Payment
            redirectUri={redirectUri} market={payment.market} amount={Math.round(payment.amount * 100)}
            getIntent={getIntent} billingDetails={{ name: payment.full_name, email: payment.email }}
          />
        </div>
        {payment.market === 'north_america' && (
          <div className='lg:col-span-4'>
            <p className='mt-8 text-sm p-4 border'>*{t('payment:north_america_disclaimer')}</p>
          </div>
        )}
      </div>
    </Container>
  )
}
